.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  margin: 0;
}

.centered-label {
  font-size: 2rem;
  font-family: Arial, sans-serif;
}